// DO NOT IMPORT THIS MODULE DIRECTLY!!!
// The API provided by this module should be consumed via the index file.
// If you need to use the types from this module, use `import type { Type } from "@host/handler";`, but that's
// it.

import { snakeCase } from "@util/strings";
import { getCoords } from "@util/dom";
import {
    FrameHandler as FrameHandlerExt,
    persistCommands,
} from "@shared/frame-handler";
import type {
    HandlerArgs,
    HandlerSpec,
    Commands,
    InternalCommands,
    IPublicHandlers,
    IframeQueryParams,
    FrameHandlerOpts,
} from "@shared/frame-handler";
import { getCampaignInput } from "@util/campaign";
import { Product, ProductLaunch, LaunchContext } from "@autocorp/ava";
import {
    referrerUrl,
    initialData,
    query,
    hostQuery,
    hostUrl,
    analyticsDebug,
} from "./query";

import { ProductType } from "@model/als";
// import { ProductType } from "@graphql/schema";

type SelectedProduct = ProductLaunch & {
    productType: ProductType;
    buffered: boolean;
}

export const isIframe = process.browser
    ? window.self !== window.parent
    : false;

const getProductType = (input: string): ProductType => {
    const selectedType = snakeCase(input).toUpperCase();
    const defaultType = ProductType.CreditTool;
    const actualType = Object.values(ProductType).find((p) => (
        p === selectedType
    )) || defaultType;

    return actualType;
};

const getFalseOrString = (v?: string | false): false | string => (
    typeof v === "boolean" ? v :
        v == null || /^(no|0|false)/i.test(v)
            ? false : v
);

type AssignedOrSymbol<T> = Required<{
    [K in keyof T]: undefined extends T[K]
    ? T[K] | symbol
    : T[K]
}>

const optional = Symbol("optional");

const defaultQueryOpts: AssignedOrSymbol<IframeQueryParams> = {
    company: "",
    widgetId: "",
    embed: false,
    version: optional,
    product: optional,
    variant: optional,
    u: "",
    // s: "",
};

const NULL_PERSIST_ID = "_";

export class FrameHandler extends FrameHandlerExt {
    public selectedProduct?: SelectedProduct;
    protected _frameParent?: Window | null;

    constructor() {
        super({
            ...Object.entries({
                ...query,
                ...defaultQueryOpts,
            })
                .reduce(
                    (acc, [key, val]) => {
                        const curKey = key as keyof FrameHandlerOpts;
                        const curVal = query.hasOwnProperty(curKey)
                            ? query[curKey]!
                            : typeof val === "symbol" ? undefined : val;
                        return Object.assign(acc, {
                            [curKey]: typeof val === "boolean"
                                ? getFalseOrString(curVal)
                                : curVal,
                        });
                    },
                    {
                        query: hostUrl?.search || "",
                        hash: hostUrl?.hash || "",
                    } as FrameHandlerOpts,
                ) as FrameHandlerOpts,
            postTarget: (process.browser && isIframe && window || {}).parent!,
            postDomain: referrerUrl.origin || hostUrl?.origin,
            debug: {
                analytics: analyticsDebug,
            },
        });

        this.setProduct(this.product || "creditTool", undefined, false);
        this._frameParent = this.postTarget as Window | null | undefined;

        this.assignee = query.assignee || "";
        this.campaign = getCampaignInput({
            ...query,
            ...hostQuery,
        });
    }

    public scrollTo(el?: HTMLElement | null) {
        if (!process.browser) return;
        if (!el) el = document.body;

        scroll({
            top: el.offsetTop,
            behavior: "smooth",
        });

        if (this.embed === "dynamic") {
            this.send("scrollToTop", {
                modalTop: getCoords(el).top,
            });
        }
    }

    public setProduct(
        input: string,
        context = this.selectedProduct?.context || {} as LaunchContext,
        buffered: boolean,
    ) {
        this.company = context.company || initialData["company"];
        this.product = input as Product;
        this.selectedProduct = {
            product: this.product,
            productType: getProductType(input),
            context,
            buffered,
        };
    }
    public productBuffered(isBuffered: boolean) {
        if (this.selectedProduct) {
            this.selectedProduct.buffered = isBuffered;
        }
    }
    get isProductBuffered(): boolean {
        return !!(this.selectedProduct?.buffered);
    }

    protected listen(): void {
        if (!isIframe) return;
        super.listen();
    }

    protected async _send<
        K extends Commands | InternalCommands,
    >(
        event: K,
        args: HandlerArgs<HandlerSpec<K>, K>,
        pid?: string, // Persisted id
    ): Promise<void> {
        if (!isIframe) return;
        super._send(event, args, pid);
    }

    protected _receive(ev: MessageEvent): void {
        // console.log(ev);
        return super._receive(ev);
    }
}

/**
 * @deprecated
 *
 * Altered behavior:
 * * Post message uses plain string
 * * Post message section delimiter is `:`
 * * No persisted messages, so no `id` section of post message
 */
export class FrameHandlerV0 extends FrameHandler {
    protected _messageDelim = ":";

    protected _persistCommand(
        command: keyof IPublicHandlers,
        args?: HandlerArgs<IPublicHandlers, Commands>,
    ) {
        return NULL_PERSIST_ID;
    }

    protected async _send<
        K extends Commands | InternalCommands,
    >(
        command: K,
        args: HandlerArgs<HandlerSpec<K>, K>,
    ): Promise<void> {
        if (!this.postDomain || !isIframe) return;
        this._frameParent?.postMessage(
            [this.selector, command, args && JSON.stringify(args)]
                .filter(Boolean)
                .join(this._messageDelim),
            this.postDomain,
        );
    }

    protected _processData(data: any) {
        const [event, ...rawArgs] = (
            data
                .split(this._messageDelim)
                .slice(1)
        ) as [Commands, string, string | undefined];
        if (!event) return;
        const args = this._getArgs(rawArgs);
        this._processHandler(event, "", args);
    }

    /**
     * Messages are strings, and begin with the Ava selector constant
     */
    protected _validateMessage(ev: MessageEvent): boolean {
        if (typeof ev.data !== "string") return false;
        if (ev.source !== this._frameParent) return false;

        if (!ev.data.startsWith(this.selector + this._messageDelim)) return false;
        return true;
    }

    protected _getArgs(
        rawArgs = [] as (string | undefined)[],
    ): IPublicHandlers[keyof IPublicHandlers] {
        try {
            return rawArgs.length > 0
                ? JSON.parse(rawArgs.join(this._messageDelim))
                : {};
        } catch (err) {
            return {};
        }
    }
}

/**
 * @deprecated
 *
 * Altered behavior:
 * * Messages are plain text
 * * Post message sections delimited with `|`
 * * Has persisted messages
 * * Sends persisted message id or `NULL_PERSIST_ID`
 */
export class FrameHandlerV1 extends FrameHandlerV0 {
    protected _messageDelim = "|";

    protected _persistCommand(
        command: keyof IPublicHandlers,
        args?: HandlerArgs<IPublicHandlers, Commands>,
    ) {
        if (!persistCommands.includes(command)) {
            return NULL_PERSIST_ID;
        }
        return super._persistCommand(command, args);
    }

    protected async _send<
        K extends Commands | InternalCommands,
    >(
        command: K,
        args: HandlerArgs<HandlerSpec<K>, K>,
        id = NULL_PERSIST_ID,
    ): Promise<void> {
        if (!this.postDomain || !isIframe) return;
        this._frameParent?.postMessage(
            [this.selector, command, id, args && JSON.stringify(args)]
                .filter(Boolean)
                .join(this._messageDelim),
            this.postDomain,
        );
    }

    protected _processData(data: any) {
        const [event, id, ...rawArgs] = (
            data
                .split(this._messageDelim)
                .slice(1)
        ) as [Commands, string, string | undefined];
        if (!event) return;
        const args = this._getArgs(rawArgs);
        this._processHandler(
            event,
            id && id !== NULL_PERSIST_ID ? id : "",
            args,
        );
    }
}

export type { IframeQueryParams };
import crossFetch, { Headers as CFHeaders } from "cross-fetch";
import { errorHandler } from "@autocorp/ava/esm/util/error-handler";
import { cookieHandler, CookieValues } from "@shared/cookies";

import {
    AnalyticsParamsInput,
    AnalyticsEventAction,
    AnalyticsContext,
    Maybe,
} from "@graphql/schema";
import { ALS_ANALYTICS_ID, ga } from "@host";
import type { TriggerEvents, CtaAnalyticsContext, CtaContextKeys } from "@shared/types";

import type { FrameHandler } from "@host/handler";

import { Sentry } from "../sentry";
import { snakeCase } from "lodash";
import { t } from "i18next";

const AVA_ANALYTICS_DEBUG_HEADER = "x-ava-analytics-debug";
const fetch = process.browser && window.fetch || crossFetch;
const Headers = process.browser && window.Headers || CFHeaders;

const AnalyticsTracking = /* graphql */ `
    mutation AnalyticsTracking($input: AnalyticsParamsInput!) {
        trackAnalytics(input: $input) {
            success
            eventType
            analyticsPayload
        }
    }
`;

// type PrefixKeys<T extends Record<string, unknown>, P extends string> = {
//     [K in keyof T as `${P}${Extract<K, string>}`]: T[K];
// }

type ErrorStatus = {
    code: number;
    status: string;
    body?: string | Record<string, unknown>;
}

type WidgetContextKeys = Exclude<keyof TriggerEvents, "CtaLoaded">;


export { AnalyticsEventAction };
export type { CtaAnalyticsContext, CtaContextKeys, WidgetContextKeys };


export class Analytics {
    protected static _AnalyticsInstance: Analytics;

    private _debugMode?: boolean;
    private _frameHandler!: FrameHandler;
    private _widgetContext?: Maybe<CtaAnalyticsContext>;
    private _ctaLoadContextList: string[] = [];
    private _clientIds: CookieValues = {};
    // private _deleteClientId!: WrappedCookieHandlerMethods["_deleteClientId"];
    private _ga4AnalyticsId = "";
    private _metaAnalyticsId = "";
    private _ga4AnalyticsDomain = "";
    private _metaAnalyticsDomain = "";
    private _sessionStarted = false;

    private _clientHeaders: Headers = new Headers({
        "Content-Type": "application/json",
        "Accept": "application/json",
    });

    public constructor(frameHandler: FrameHandler) {
        if (
            Analytics._AnalyticsInstance
            && Analytics._AnalyticsInstance._frameHandler === frameHandler
        ) {
            return Analytics._AnalyticsInstance;
        }

        this._frameHandler = frameHandler;
        this._debugMode = (frameHandler.debug || {}).analytics;

        // console.log("AnalyticsClient", (window as any)["ANALYTICS_CLIENT"] = this);
        if (this._debugMode) {
            this._clientHeaders.set(AVA_ANALYTICS_DEBUG_HEADER, "true");
            // console.log("AnalyticsHeaders", (window as any)["ANALYTICS_HEADERS"] = this._clientHeaders);
        }

        Analytics._AnalyticsInstance = this;
    }

    // private _getCookie() {
    //     return cookieHandler.getClientId("ava-analytics");
    // }

    private _locMeta(): Pick<
        AnalyticsParamsInput,
        // TODO: Change to company id
        | "widgetId"
        | "sourceUrl"
        | "referrer"
    > {
        return {
            widgetId: this._frameHandler.widgetId,
            referrer: document.referrer || this._frameHandler.sourceUrl,
            sourceUrl: this._frameHandler.sourceUrl,
        };
    }

    private async track(
        clientId: string,
        action: AnalyticsEventAction,
        inputContext: CtaAnalyticsContext,
        internal = false,
    ) {
        const {
            clientId: somethingElse,
            ...context
        } = inputContext;
        if (!clientId) return;

        const payload: AnalyticsParamsInput = {
            ...this._locMeta(),
            internalCall: internal,
            eventAction: action,
            context: context,
            clientId,
        };

        // add temporary logging to sentry when ctaProduct is missing
        if (!inputContext.ctaProduct) {
            Sentry.captureMessage("Missing CTA data", {
                level: Sentry.Severity.Info,
                extra: { ...inputContext },
            });
        }

        if (this._debugMode) {
            console.log("Tracking analytics Request Headers:\n");
            console.log(this._clientHeaders);
            console.log(Object.entries(this._clientHeaders));

            console.log("Sending tracking analytics:\n", payload);
        }

        this._clientHeaders.set(ALS_ANALYTICS_ID, this.getClientIds() || "");

        try {
            const result = await fetch(API_URL, {
                method: "POST",
                headers: this._clientHeaders,
                credentials: "include",
                body: JSON.stringify({
                    query: AnalyticsTracking,
                    variables: {
                        input: payload,
                    },
                }),
            });

            if (!result.ok) {
                const err: ErrorStatus = {
                    code: result.status,
                    status: result.statusText,
                };

                const body = await result.text();

                try {
                    err.body = JSON.parse(body);
                } catch (e) {
                    err.body = body;
                }

                new errorHandler
                    .ResponseError("Analytics tracking failed", err, {
                        action,
                        context,
                        internal,
                    })
                    .logError();
                return;
            }

            const data = await result.json();
            if (data.errors) {
                throw new errorHandler.ResponseError(
                    "Errors generated during analytics tracking",
                    data,
                    {
                        errors: data.errors
                            .reduce((
                                acc: Record<string, unknown>,
                                err: any,
                                i: number,
                            ) => ({ [i]: err }), {}),
                    },
                );
            }
        } catch (err) {
            new errorHandler.CriticalError(err, {
                payload,
            }).logError();
            return;
        }
    }

    private async procAnalytics(
        action: AnalyticsEventAction,
        context: CtaAnalyticsContext,
        internal = false,
    ) {
        const clientIds = this._clientIds =
            await cookieHandler.getAllClientIds(context.clientId || this._clientIds);

        for (const inputId of Object.values(clientIds || {})) {
            this.track(inputId, action, context, internal);
        }

    }

    public setGa4AnalyticsId(id: string) {
        this._ga4AnalyticsId = id;
    }

    public setMetaAnalyticsId(id: string) {
        this._metaAnalyticsId = id;
    }

    public getGa4AnalyticsId() {
        return this._ga4AnalyticsId;
    }

    public getMetaAnalyticsId() {
        return this._metaAnalyticsId;
    }
    public setGa4AnalyticsDomain(domain: string) {
        this._ga4AnalyticsDomain = domain;
    }

    public setMetaAnalyticsDomain(domain: string) {
        this._metaAnalyticsDomain = domain;
    }

    public getGa4AnalyticsDomain() {
        return this._ga4AnalyticsDomain;
    }

    public getMetaAnalyticsDomain() {
        return this._metaAnalyticsDomain;
    }

    /**
     * Returns a string of all client IDs if the `id` parameter is undefined,
     * otherwise returns a single client ID string specified by the `id`.
     * */
    public getClientIds(id?: keyof CookieValues) {
        if (!id) {
            return Object.values(this._clientIds).join(";");
        }
        return this._clientIds[id];
    }

    public async trackCta(
        event: (
            | AnalyticsEventAction.CtaLoaded
            | AnalyticsEventAction.CtaImpression
        ),
        context: CtaAnalyticsContext,
    ): Promise<void> {
        const strContext = JSON.stringify({
            ...this._locMeta(),
            event,
            context: Object.keys(context).sort().reduce((acc, key) => ({
                ...acc,
                [key]: context[key as keyof CtaAnalyticsContext],
            }), {}),
        });

        if (
            event === AnalyticsEventAction.CtaLoaded && this._ctaLoadContextList.includes(strContext)
        ) return;

        this.procAnalytics(event, {
            ...context,
            ctaDetail: [
                context.ctaProduct,
                context.ctaType,
                context.ctaVersion,
                context.ctaTheme,
            ].map((v) => v || "").join("/"),
        }, false);

        this._ctaLoadContextList.push(strContext);
    }

    public startSession(context: CtaAnalyticsContext) {
        if (this._sessionStarted) return;

        TEST && console.log("widgetContext:", context);
        this._widgetContext = context;
        this._sessionStarted = true;
    }
    public endSession() {
        this._widgetContext = null;
    }
    public async trackWidget(
        action: WidgetContextKeys,
        internal: boolean,
        context?: AnalyticsContext,
    ): Promise<void> {
        const useAction = AnalyticsEventAction[action];
        if (!useAction) return;


        this.procAnalytics(
            useAction as unknown as AnalyticsEventAction,
            // TODO: This should prefix all calling cta context `widgetCta`
            {
                ...this._widgetContext || {},
                widgetProduct: this._frameHandler.product,
                widgetVersion: NEXT_PUBLIC_GIT_COMMIT_SHA!, // WIDGET_VERSION,
                ...context || {},
            },
            internal,
        );
    }
}

/* eslint-disable prefer-rest-params */

declare global {
    interface Window {
        gtag: IGtagFn;
    }
}

class GaApi {
    private _trackingEnabled = false;
    private _globalObj = process.browser
        ? window
        : global;

    private get datalayer() {
        return (this._globalObj as any).dataLayer = (this._globalObj as any).dataLayer || [];
    }

    public get gtag(): IGtagFn {
        let curGtag = this._globalObj.gtag;
        if (!curGtag) {
            const getLayer = () => this.datalayer;
            curGtag = this._globalObj.gtag = function gtag() {
                getLayer().push(arguments as any);
            } as IGtagFn;
        }
        return curGtag;
    }

    /* If no `tagId` is specified, default GA_ID will be used. **/
    public config(tagId?: string, variables?: GtagConfigVariables) {
        this.gtag("js", new Date());
        if (!tagId && variables) {
            this.gtag("config", GA_ID, variables);
            return;
        }
        this.gtag("config", tagId!, variables || {});
    }

    public set(layerVariables: GtagViewVariables) {
        this.gtag("set", layerVariables);
    }

    public event<E extends keyof Gtag["event"]>(
        event: E,
        ...context: Gtag["event"][E]
    ) {
        this.gtag("event", event, ...context as any);
    }

    public trackView(to_screen: string, from_screen = "") {
        if (!this._trackingEnabled) return;
        this.event("screen_view", {
            to_screen,
            from_screen,
            page_path: to_screen,
            page_title: to_screen,
            screen_name: to_screen,
            app_name: "AskAvaWidget",
            send_to: GA_ID,
        });
    }

    public enableTracking(enable: boolean) {
        this._trackingEnabled = enable;
    }
}

export const ga = new GaApi();

// DO NOT IMPORT THIS MODULE DIRECTLY!!!
// The API provided by this module should be consumed via the index file.
// If you need to use the types from this module, use `import type { Type } from "@host/query";`, but that's
// it.

import { IframeQueryParams } from "@shared/frame-handler";
import { AVA_LOAD_DATA, Product } from "@autocorp/ava";
import { normalizeUrl } from "@util/url";
import { getQueryParams } from "@util/query";
import { ANALYTICS_DEBUG_PARAM } from "./constants";

type ToStringBoolean<T> = T extends false ? "false" : T;
type ToStringLiteral<T> = T extends string ? T : never;

type ValuesToString<T> = {
    [K in keyof T]: ToStringLiteral<
        ToStringBoolean<T[K]>
    >;
}

declare global {
    interface Window {
        [AVA_LOAD_DATA]: (
            Partial<ValuesToString<IframeQueryParams>> &
            Record<string, string>
        );
    }
}

export const initialData = process.browser && window[AVA_LOAD_DATA] || {};

// Backwards compatibility with older dynamic embed, which uses `feature` query param
if (initialData.feature && !initialData.product) {
    initialData.product = initialData.feature as Product;
}

const createUrl = (url: string): URL | undefined => {
    try {
        if (url) {
            if (!url.startsWith("http")) {
                url = `https://` + url;
            }
            return new URL(normalizeUrl(url));
        }
    } catch (err) {
        // noop
    }
};

export const referrerUrl = (
    process.browser
    && document.referrer
    && new URL(document.referrer)
    || {} as URL
);

const tryUrls = [
    initialData.u && (
        initialData.u.match(/[?#]/)
        && initialData.u
        || [
            initialData.u,
            initialData.s,
        ].filter(Boolean).join("")
    ),
    [initialData.domain, initialData.path].filter(Boolean).join("/"),
    referrerUrl.href,
].filter(Boolean) as string[];

export const hostUrl: URL | undefined = tryUrls
    .map((url) => createUrl(url))
    .find((url) => !!url);

if (hostUrl) {
    hostUrl.search = (
        hostUrl.search
        || initialData.s
        || referrerUrl.search
        || ""
    );
    hostUrl.hash = (
        hostUrl.hash
        || referrerUrl.hash
        || ""
    );
}

if (hostUrl) {
    initialData.domain = hostUrl.origin;
    initialData.path = hostUrl.pathname;
}

initialData.domain = initialData.domain?.replace(/\/+$/, "") || "";

export const hostQuery = process.browser && (
    hostUrl && getQueryParams(hostUrl.search)
) || {};

export const query = (
    process.browser
    && { ...initialData }
    || {}
) as Record<string, string>;

if (hostUrl) {
    query.u = hostUrl.href;
    // query.s = query.s || hostUrl.search;
}

const enabled = (...values: Array<string | boolean | void>): boolean => (
    values.some((v) => v == null ? false : (
        typeof v === "boolean" ? v :
            v === "" || /^(y(es)?|1|true)$/i.test(String(v))
    ))
);

export const analyticsDebug = !IS_PRODUCTION && enabled(
    ANALYTICS_DEBUG,
    hostQuery[ANALYTICS_DEBUG_PARAM],
);

export const testLeads = IS_PRODUCTION
    ? enabled(hostQuery.testLeads)
    : true;
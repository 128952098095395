import uuidv5 from "uuid-browser/v5";
import { AnalyticsClientIds } from "@shared/types";

const NS = "c9ac28e2-c4a2-4c04-a25c-5b8f77451fcd";
/** Client ID expires in 2 years */
const EXP_SPAN = 2 * 365 * 24 * 60 * 60 * 1000;

export type CookieTypes = keyof AnalyticsClientIds
export type CookieValues = AnalyticsClientIds;

export type GetCookieFn = (type: string) => Promise<string | void>;

export const getCookie: GetCookieFn = async (name) => {
    const cookie = document.cookie
        .split(/\s*;\s*/)
        .find((c) => c.trim().startsWith(name + "="));
    return cookie ? cookie.slice(cookie.indexOf("=") + 1) : "";
};

type SetCookieFn = (name: string, value: string, expMs?: number) => void;
const setCookie = (name: string, value: string, expMs = EXP_SPAN) => {
    const dt = new Date();
    dt.setTime(dt.getTime() + expMs);
    document.cookie = ([
        [name, value],
        ["samesite", "None"],
        ["secure"],
        ["expires", dt],
    ])
        .map((p) => p.join(p.length === 1 ? "" : "="))
        .join("; ");
};

type GetClientIdFn = (type: string, getExternal: (cb: GetCookieFn) => ReturnType<GetCookieFn>) => ReturnType<GetCookieFn>;
const getClient: GetClientIdFn = (type, getExternal = async () => void 0) => {
    return getExternal(getCookie);
};

type GetAllClientIdsFn = (existing?: CookieValues) => Promise<CookieValues>

const analyticsIds: Exclude<AnalyticsClientIds, void> = {};

const maybeFormatClientId = (type: CookieTypes, value?: string | void) => {
    if (!value) return;
    switch (type) {
        case "GOOGLE_A4": {
            return value.split(".").slice(-2).join(".");
        }
        default: {
            return value;
        }
    }
};
const getAllClientIds: GetAllClientIdsFn = async (existing = {}) => {
    const ret: CookieValues = {};
    const promises: Promise<CookieValues>[] = [];
    for (const [key, val] of Object.entries(otherAnalytics)) {

        const k = key as CookieTypes;
        promises.push((async () => {
            const value = (
                existing[k]
                || analyticsIds[k]
                || maybeFormatClientId(k, await val())
            );
            if (!value) return ret;
            return Object.assign(ret, {
                [k]: value,
            });

        })());
    }

    await Promise.all(promises);
    return ret;
};

// const killClient = (name: string): void => {
//     if (getCookie(name)) setCookie(name, "", 0);
//     if (name in cachedClients) delete cachedClients[name];
// };

const getCookieName = (ns: string, type: string) => (
    ns + "-" + type + "-" + uuidv5(ns + "-" + type, NS)
);

export type CookieHandlerKeys = keyof CookieHandler;
export type CookieHandler = {
    getClientId: GetClientIdFn;
    setCookie: SetCookieFn;
    getAllClientIds: GetAllClientIdsFn
    // deleteClientId: () => void;
    // getCtaSession: () => string;
    // getWidgetSession: () => string;
    // deleteWidgetSession: () => void;
};

export const cookieHandler: CookieHandler = {
    getClientId: ((analyticsType, getExternal) => {
        return getClient(getCookieName("ava-analytics", analyticsType), getExternal);
    }) as typeof getClient,
    getAllClientIds,
    setCookie,
};

declare global {
    interface Window {
        gtag: IGtagFn;
    }
}

const sleep = async <R>(cb: () => Promise<R>, time: number): Promise<R> => {
    return new Promise<R>((resolve) => {
        setTimeout(() => { resolve(cb()); }, time);
    });
};

const maxRetries = 5;
const otherAnalytics: Partial<
    Record<CookieTypes, (retries?: number) => Promise<string | void>>
> = {
    GOOGLE_A4: async function ga4ClientId(retries = 0): Promise<string | void> {
        const curRetries = retries;

        return cookieHandler.getClientId("GOOGLE_A4", async (getCookie) => {
            return Promise.resolve().then(async() => {
                return getCookie("_ga")
                    .then((val) => val || getCookie("_gcl_au"))
                    .then((val) => val || getCookie("_gid"));

            }).then((cookieId) => {
                if (!cookieId && "gtag" in window) {
                    return window.gtag("get", GA_ID, "client_id", (cid: any) => {
                        return cid;
                    });
                }
                return cookieId;
            }).then((cookieId) => {
                if (!cookieId && retries < maxRetries) {
                    // TODO: Return only one promise..
                    if (RUNTIME_DEBUG) console.log("Pausing analytics collection:", curRetries);
                    return sleep(() => ga4ClientId(curRetries + 1), 2000);
                }
                return cookieId;
            });
        });
    },
};


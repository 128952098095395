import { normalizeUrl } from "@autocorp/ava/esm/util/url";
import { camelCase } from "@autocorp/ava/esm/util/strings";
import { CampaignInput } from "@autocorp/ava/esm/util/campaign";
import { debounce } from "@autocorp/ava/esm/util/debounce";

import { TriggerEmitOptions } from "./types";
import { Product } from "@autocorp/ava";
import { ClientLaunchContext } from "./types";

export type RequestHandler<A = undefined> = (A extends undefined
    ? () => void
    : (args: A) => void
);

interface IInternalHandlers {
    ack: {
        id: string;
    };
    resend: undefined;
}

export interface IPublicHandlers {
    hide: undefined;
    resize: {
        width: number;
        height: number;
    }
    reportSize: undefined;
    scrollToTop: {
        modalTop?: number;
    };
    load: undefined;
    cta: ClientLaunchContext;
    ctaImpr: ClientLaunchContext;
    launch: {
        /**
         * @deprecated use `product`
         */
        feature?: Product;
        product: Product;
        context: ClientLaunchContext;
    };
    trigger: TriggerEmitOptions;
}
type RequestHandlers = {
    [K in keyof IPublicHandlers]: RequestHandler<IPublicHandlers[K]>[];
}
type InternalRequestHandlers = RequestHandlers & {
    [K in keyof IInternalHandlers]: RequestHandler<IInternalHandlers[K]>[];
};
export type Commands = keyof RequestHandlers;
export type CommandHandler<T extends Commands> = RequestHandlers[T][0];

export type InternalCommands = keyof IInternalHandlers;

export type HandlerSpec<T extends Commands | InternalCommands> = (
    T extends Commands
    ? IPublicHandlers
    : IInternalHandlers
)

export type HandlerArgs<
    T extends IPublicHandlers | IInternalHandlers,
    C
> = T[Extract<C, keyof T>] extends undefined
    ? undefined
    : T[Extract<C, keyof T>];

type CommandsWithoutArgs<T> = Exclude<
    {
        [K in keyof T]: T[K] extends undefined
        ? K
        : never
    }[keyof T],
    undefined
>;
type CommandsWithArgs<T> = Exclude<
    {
        [K in keyof T]: T[K] extends undefined
        ? never
        : K
    }[keyof T],
    undefined
>;

export interface IQueryParamOpts {
    company?: string;
    /** @deprecated use `company` */
    widgetId: string;
    embed: "dynamic" | "static" | false;
    product?: Product;
    version?: string;
    variant?: string;
}

export type FrameHandlerOnloadHook = (
    handler: FrameHandler,
    doLoad: () => void,
) => void;

/** Use FrameHandlerOpts below */
interface IFrameHandlerOpts extends IQueryParamOpts {
    domain: string;
    path: string;
    query: string;
    hash: string;
    postTarget?: NonNullable<Window | HTMLIFrameElement>;
    postDomain?: string;
    global?: boolean;
    type?: string;
    debug: {
        analytics?: boolean;
    };
    onLoad?: FrameHandlerOnloadHook;
    analyticsId?: Promise<string | void>;
    bootstrapVersion?: string;
}

export type IframeQueryParams = Partial<IQueryParamOpts> & {
    u: string;
    // s: string;
};

export interface IPostMessage {
    ns: string;
    event: keyof IPublicHandlers;
    args: IPublicHandlers[keyof IPublicHandlers];
    pid?: string;
}


export interface FrameHandler extends IFrameHandlerOpts { }
export type FrameHandlerOpts = Partial<IFrameHandlerOpts>;

interface IPersistedMessage {
    i: FrameHandler;
    c: Commands;
    a?: HandlerArgs<IPublicHandlers, Commands>;
}

const persistedMessageQueue = new Map<string, IPersistedMessage>();

export const persistCommands: Array<keyof IPublicHandlers> = [
    "launch",
    "load",
    "cta",
    "ctaImpr",
];

const MAX_SAFE_INTEGER = 9007199254740991;
const getPersistedMessageId = (): string => {
    let id: number;
    for (id = 0; id < MAX_SAFE_INTEGER; ++id) {
        if (!persistedMessageQueue.has(id.toString())) break;
    }
    return id.toString();
};

export class FrameHandler {
    private _handlers: Partial<InternalRequestHandlers> = {};
    private _loaded = false;

    public postDomain?: string;
    public initialPathname!: string;
    public campaign?: CampaignInput;
    public assignee?: string;

    public get selector(): string { return AVA_ID; }

    public get sourceUrl(): string {
        return normalizeUrl([
            this.domain,
            this.path,
        ].filter(Boolean).join("/") + [
            this.query,
            this.hash,
        ].filter(Boolean).join(""));
    }

    public get ready(): boolean {
        return (this._handleLoad && this._loaded) || !this._handleLoad;
    }

    private _postWindow?: Window | null;

    private _product: Product | undefined | string;
    public get product(): Product | undefined {
        return this._product as Product | undefined;
    }
    public set product(value: Product | undefined) {
        this._product = value && camelCase(value) || value;
    }

    constructor(opts: FrameHandlerOpts, private _handleLoad = false) {
        if (typeof window === "undefined") return this;

        const ass = Object.assign as <
            V extends any,
            X = V,
        >(...o: [V, ...X[]]) => V;

        // console.log("FrameHandler opts:", opts);

        ass<
            this,
            FrameHandlerOpts
        >(
            this,
            opts,
            {
                debug: ass(
                    {
                        analytics: ANALYTICS_DEBUG,
                    },
                    opts.debug || {},
                ),
            },
        );

        if (
            this.postTarget instanceof HTMLIFrameElement
            && this._handleLoad
        ) {
            const handleLoad: FrameHandlerOnloadHook = opts.onLoad || ((
                handler: FrameHandler,
                doLoad: () => void,
            ) => { doLoad(); });
            const loadListener = (() => {
                handleLoad(this, () => {
                    this._loaded = true;
                    this._handleResend();
                });
                this.postTarget!.removeEventListener("load", loadListener);
            });
            this.postTarget.addEventListener("load", loadListener);
        } else {
            this._loaded = true;
        }

        this.initialPathname = location.pathname.replace(/\/index(\.html)?$/, "/");
        this.listen();
    }

    private _handleAck: RequestHandler<IInternalHandlers["ack"]> = ({ id }) => {
        persistedMessageQueue.delete(id);
    };
    private _handleResend: RequestHandler<IInternalHandlers["resend"]> = () => {
        persistedMessageQueue.forEach(({ i, c, a }, id) => {
            if (i === this) {
                this._send(c, a, id);
            }
        });
    };
    private _doResend = debounce(() => {
        this._send("resend", undefined);
    }, 100);

    protected listen(): void {
        window.addEventListener("message", this._receive.bind(this));
        if (typeof this.postTarget !== "undefined") {
            this._on("ack", this._handleAck.bind(this));
            this._on("resend", this._handleResend.bind(this));
        }
    }

    private _getCommandListeners<
        T extends Commands | InternalCommands
    >(command: T): InternalRequestHandlers[T] {
        if (command in this._handlers) {
            return this._handlers[command]! as InternalRequestHandlers[T];
        }
        return this._handlers[command] = [];
    }

    private _on<T extends Commands | InternalCommands>(
        command: T,
        fn: InternalRequestHandlers[T][0],
    ): void {
        this._getCommandListeners(command).push(fn as CommandHandler<any>);
    }
    public on<T extends Commands>(
        command: T,
        fn: CommandHandler<T>,
    ): void {
        this._on(command, fn);
        if (persistCommands.includes(command)) {
            this._doResend();
        }
    }

    private _off<T extends Commands | InternalCommands>(
        command: T,
        fn: InternalRequestHandlers[T][0],
    ): void {
        const listeners = this._getCommandListeners(command);
        for (let i = listeners.length - 1; i >= 0; --i) {
            if (listeners[i] === fn) {
                listeners.splice(i, 1);
            }
        }
    }
    public off<T extends Commands>(
        command: T,
        fn: CommandHandler<T>,
    ): void {
        this._off(command, fn);
    }

    protected _persistCommand(
        command: keyof IPublicHandlers,
        args?: HandlerArgs<IPublicHandlers, Commands>,
    ) {
        const shouldPersist = (
            persistCommands.includes(command)
            || !this.ready
        );
        if (!shouldPersist) return;

        const id = getPersistedMessageId();
        persistedMessageQueue.set(id, {
            i: this,
            c: command,
            a: args,
        });
        return id;
    }

    public send<T extends CommandsWithoutArgs<IPublicHandlers>>(
        command: T,
    ): void;
    public send<T extends CommandsWithArgs<IPublicHandlers>>(
        command: T,
        args: HandlerArgs<IPublicHandlers, T>,
    ): void;
    public async send(
        event: Commands,
        args?: HandlerArgs<IPublicHandlers, Commands>,
    ): Promise<void> {
        const id = this._persistCommand(event, args);
        this.ready && this._send(event, args, id);
    }

    protected async _send<
        K extends Commands | InternalCommands,
    >(
        event: K,
        args: HandlerArgs<HandlerSpec<K>, K>,
        pid?: string, // Persisted id
    ): Promise<void> {
        const trg = this.postTarget;
        const postTo = this._postWindow = this._postWindow || (
            trg instanceof HTMLIFrameElement
                ? trg.contentWindow
                : trg
        );
        try {
            postTo && (
                // console.log(`[iframe: ${window.self !== window.parent}]::postMessage:`, {
                //     postTo,
                //     payload: {
                //         ns: this.selector,
                //         event,
                //         pid,
                //         args,
                //     },
                //     postDomain: this.postDomain,
                // }),
                postTo.postMessage(
                    {
                        ns: this.selector,
                        event,
                        pid,
                        args,
                    } as IPostMessage,
                    this.postDomain || "*",
                )
            );
        } catch (err) {
            console.log("Failed to postMessage:", {
                postTo,
                payload: {
                    ns: this.selector,
                    event,
                    pid,
                    args,
                },
                postDomain: this.postDomain,
            });
        }
    }

    protected _receive(ev: MessageEvent): void {
        if (!this._validateMessage(ev)) return;
        return this._processData(ev.data);
    }

    protected _validateMessage(ev: MessageEvent): boolean {
        if (typeof ev.data !== "object") return false;
        if (ev.origin !== this.postDomain && this.global) return false;
        if (ev.source !== this._postWindow && !this.global) return false;
        return true;
    }

    protected _processData(data = {} as IPostMessage) {
        try {
            const {
                ns,
                event,
                args = {},
                pid,
            } = data;
            if (ns !== this.selector) return;

            this._processHandler(event, pid, args);
        } catch (err) {
            return;
        }
    }

    protected _processHandler(
        event: keyof IPublicHandlers,
        pid?: string,
        args = {} as IPublicHandlers[keyof IPublicHandlers],
    ) {
        if (event in this._handlers) {
            const listeners = this._getCommandListeners(event);

            if (listeners.length > 0 && pid) {
                this._send("ack", { id: pid });
            }

            listeners.forEach((cb: RequestHandler<any>, i) => cb(args));
        }
    }
}

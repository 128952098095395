import { Sentry } from "@autocorp/sentry";
import { escapedRegex, escapeForRegex } from "@util/regex";

const makeOneLinePattern = (val: string) => (
    new RegExp("^" + escapeForRegex(val) + "(\\n|$)", "i")
);

export const initSentry = () => {
    if (!process.browser) return;
    Sentry.init((options) => ({
        ...options,
        // integrations: [
        //     new AvaInboundFilter(),
        // ],
        beforeBreadcrumb: (breadcrumb, hint) => {
            if (breadcrumb?.category === "navigation") {
                if (breadcrumb?.data?.manual) {
                    delete breadcrumb.data.manual;
                } else {
                    return null;
                }
            }
            return breadcrumb;
        },
        allowUrls: IS_PRODUCTION
            ? [escapedRegex(WIDGET_URL, "i")]
            : [/^$/],
        ignoreErrors: [
            "cancelled",
            "cancelado",
            "load failed",
            "failed to fetch",
        ].map<RegExp | string>(makeOneLinePattern).concat([
            "evaluating 'window.webkit.messageHandlers'",
            "Cannot redefine property: BetterJsPop",
            "Cannot redefine property: googletag",
        ].map((v) => escapedRegex(v, "i"))),
    }));
};

export { Sentry };

// process.browser && (
//     (window as any)["__SENTRY_NO_DEBUG__"] = false,
//     (window as any)["__SENTRY_BROWSER_BUNDLE__"] = false,
//     setTimeout(() => {
//         (window as any)["__SENTRY__"].logger.enable();
//         throw new Error("Cancelled");
//     }, 1200)
// );
import {
    MOUSEFLOW_READY,
    MIXPANEL_READY,
    GA_READY,
    ALL_READY_KEYS,
} from "./constants";

const retrieveReadyFn = (key: ALL_READY_KEYS) => (
    process.browser ? window[key] : {
        then: () => null,
        ready: () => null,
    }
);

export const gaReady = retrieveReadyFn(GA_READY);
export const mouseflowReady = retrieveReadyFn(MOUSEFLOW_READY);
export const mixpanelReady = retrieveReadyFn(MIXPANEL_READY);

import { mouseflowReady } from "@host/onload";

declare global {
    interface Global {
        mouseflow?: MouseflowGlobalObj;
    }
    interface Window {
        mouseflow?: MouseflowGlobalObj;
        _mfq?: MouseflowQueue;
    }
}

type MouseflowGlobalObj = { start: VoidFunction } & Record<any, unknown>;
type MfqArrayItems =
    [string]
    | [string, unknown]
    | [string, unknown, unknown];
type MfqCmd = MfqArrayItems | VoidFunction;
type MouseflowQueue = Array<MfqCmd>;

export type MouseflowSessionVariables = {
    widgetVersion: string;
    widgetId?: string;
    company?: string;
    productType?: string;
    ctaId?: string;
    variant?: string;
    embedded?: string | boolean;
    leadId?: string;
}

let isRecording = false;
let variables = {} as MouseflowSessionVariables;

const getMfqInstance = () => {
    return window._mfq = window._mfq || [];
};

const pushVariables = (key?: string) => {
    if (!isRecording) return;

    const inst = getMfqInstance();
    if (key) {
        const useKey = key as keyof MouseflowSessionVariables;
        inst.push(["setVariable", key, variables[useKey]]);
    } else {
        Object.keys(variables).forEach((key) => {
            inst.push(["setVariable", key, variables[key as keyof MouseflowSessionVariables]]);
        });
    }
};

/** Adds a virtual page view to mouseflow */
const pageView = (path: string) => {
    const inst = getMfqInstance();
    inst.push(["newPageView", path]);
};

/** Start Mouseflow recording */
const start = () => {
    if (isRecording) return;
    isRecording = true;
    mouseflowReady.then(() => {
        if (!window.mouseflow) {
            setTimeout(() => {
                isRecording = false;
                start();
            }, 200);
            return;
        }
        if (window.mouseflow.start) {
            window.mouseflow.start();
            pushVariables();
        }
    });
};

/** Stop Mouseflow recording */
const stop = () => {
    if (!isRecording) return;
    isRecording = false;
    getMfqInstance().push(["stop"]);
};

/** Set custom variables (key-value pairs) in Mouseflow */
function set(variables: MouseflowSessionVariables): void;
function set<K extends keyof MouseflowSessionVariables>(key: K, value: MouseflowSessionVariables[K]): void;
function set<K extends keyof MouseflowSessionVariables = keyof MouseflowSessionVariables>(
    input: MouseflowSessionVariables | K,
    value?: MouseflowSessionVariables[K],
) {
    if (typeof input === "object") {
        variables = input as MouseflowSessionVariables;
        pushVariables();
    } else {
        const key = input;
        if (value) {
            variables[key] = value;
            pushVariables(key);
        }
    }
}

export const mfq = {
    start,
    stop,
    set,
    pageView,
};
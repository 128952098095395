import {
    createStore,
    AnyStateModelCollection,
    StateOfModelCollection,
    StateModel,
    StoreInstance,
} from "@autocorp/redux";
import crossFetch, { Headers as CFHeaders } from "cross-fetch";
import { errorHandler } from "@autocorp/ava/esm/util/error-handler";
import { mockProfile, rootApi } from "@api/api-client";
import { GetLeadInfo } from "@api/query/lead";
import { transformFromPhone } from "@util/phone";
import { DealerModel } from "./dealer";
import { UserModel, IUserState } from "./user";
import { ProductsModel } from "./products";
import { DatalayerModel } from "./datalayer";
import { CoreModel } from "./core";

const fetch = process.browser && window.fetch || crossFetch;
const Headers = process.browser && window.Headers || CFHeaders;

const rootModels = {
    UserModel,
    DealerModel,
    ProductsModel,
    DatalayerModel,
    CoreModel,
};

type StateOfRootModels = StateOfModelCollection<typeof rootModels>;

export const getCombinedModels = <T extends AnyStateModelCollection>(models: T) => ({
    ...rootModels,
    ...models,
});

const fetchLead = async (accessToken: string) => {
    try {
        const result = await fetch(API_URL, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept": "application/json",
            }),
            body: JSON.stringify({
                query: GetLeadInfo,
                variables: { accessToken },
            }),
        });
        const resultJson = await result.json();
        return resultJson.data.getLeadInfo;
    } catch (error) {
        console.error("Unable to fetch lead data:", error);
        new errorHandler.CriticalError(error, { accessToken }).logError();
        return;
    }
};

const composeLeadData = async (accessToken: string) => {
    try {
        const lead = await fetchLead(accessToken);
        const leadData: Record<string, any> = {
            user: {
                "leadId": lead.id,
                "firstName": lead.firstName,
                "lastName": lead.lastName,
                "emailAddress": lead.emailAddress,
                "phoneNumber": transformFromPhone(lead.phoneNumber),
                // Format DOB from YYYY-MM-DD to DD/MM/YYYY
                "dateOfBirth": lead.dateOfBirth.split("-").reverse().join("/"),
                "address": `${lead.streetNumber} ${lead.street}`,
                "unit": "",
                "postalCode": lead.postalCode,
                "city": lead.city,
                "province": lead.provinceCode,
                "yearlyIncome": Math.round(lead.incomeAmount * 12) || 0,
                "employmentStatus": lead.employmentStatus || "",
            },
            creditTool: {
                "obtainScoreConsent": true,
                "dealerCreditConsent": true,
                "consentCollectCredit": true,
            },
        };
        return leadData;
    } catch (error) {
        console.error("Something went wrong composing lead data:", error);
        return;
    }
};

export const getLeadData = async (): Promise<
    Record<string, any> | undefined
> => {
    const queryParamsList = location.search.replace("?", "").split("&");
    const queryParamCollection = queryParamsList.reduce(
        (acc, queryParam) => {
            const [key, value] = queryParam.split("=");
            if (key) acc[key] = value;
            return acc;
        },
        {} as Record<string, any>,
    );

    if ("accessToken" in queryParamCollection ) {
        const accessToken = queryParamCollection["accessToken"];

        const leadData = await composeLeadData(accessToken);
        return leadData || undefined;
    }
    return undefined;
};

export const hydrateModel = async <T extends StateModel<any>>(
    model: T,
    instance: StoreInstance<any>,
    hydrateData: Record<string, any> | undefined = undefined,
) => {
    if (hydrateData && hydrateData.hasOwnProperty(model.name)) {
        const modelData = hydrateData[model.name];
        const modelApi = instance.model[model.name];
        modelApi?.merge(modelData as any);

        (model as any).defaultState = {
            ...model.defaultState,
            ...(modelApi?.state || {}),
        };
        return;
    }

    if (MOCK || TEST) {
        const profile = mockProfile;
        console.log(TEST
            ? `Loading test profile for ${model.name}`
            : `Loading mock profile ${profile} for ${model.name}`,
        );

        const mockData = (await import(`./__fixtures/profile${profile}.json`)) || {};

        if (mockData.default.hasOwnProperty(model.name)) {
            const data = mockData.default[model.name];
            const modelApi = instance.model[model.name];
            modelApi?.merge(data as any);

            (model as any).defaultState = {
                ...model.defaultState,
                ...modelApi?.state || {},
            };
        }
    }
};

const rootStore = createStore(rootModels, {
    events: {
        postReset: () => {
            rootApi.reset();
        },
        postInit: async (instance) => {
            const hydrateData = await getLeadData();

            for(const model of Object.values(rootModels)) {
                await hydrateModel(model, instance, hydrateData);
            }
        },
    },
    enhancer: (
        !IS_PRODUCTION &&
        process.browser &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    ) || undefined,
});

export const useStoreInstance = (preloadedState?: StateOfRootModels) => (
    rootStore.useStoreInstance(preloadedState, {
        fireEvents: process.browser,
    })
);

export const attachModel = rootStore.attach;

export const Provider = rootStore.Provider;
export const useRootStore = () => rootStore.useStore();
export const useRootModels = () => rootStore.useModel();

export type { IDealerState } from "./dealer";
export type { IUserState };
